.slider {
  height: 90vh;
  margin-bottom: 0.5rem;
}

.left-arrow {
  position: absolute;
  top: 45%;
  left: 32px;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.5;
  z-index: 1;
}

.slide.active {
  opacity: 1;
  width: 100%;
  height: 88%;
  object-fit: cover;
  object-position: center;
  -webkit-clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0% 0%);
  clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0% 0%);
}

.slide {
  opacity: 0;
  transition: 500ms opacity ease-in-out;
  width: 100%;
  height: 88%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  -webkit-clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0% 0%);
  clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0% 0%);
}

.info-container {
  display: flex;
  /* display: inline-block; */
  flex-direction: column;
  width: 40%;
  /* height: 100%; */
  position: absolute;
  top: 45%;
  right: 30px;
}

.title.active {
  opacity: 1;
  transition-delay: 700ms;
  font-size: 4rem;
}

.title {
  opacity: 0;
  transition: 200ms opacity ease-in-out;
  font-size: 4rem;
}

.desc.active {
  opacity: 1;
  padding-top: 1.5em;
  transition-delay: 700ms;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 3px;
}

.desc {
  opacity: 0;
  padding-top: 1.5em;
  transition: 200ms opacity ease-in-out;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 3px;
}

.right-arrow {
  position: absolute;
  top: 45%;
  right: 32px;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.5;
  z-index: 1;
}

.button.active {
  opacity: 1;
  transition-delay: 700ms;
  padding: 0.5rem;
  margin-top: 2.5rem;
  width: 8rem;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
}

.button {
  opacity: 0;
  padding: 0.5rem;
  margin-top: 2.5rem;
  width: 8rem;
  font-size: 20px;
  transition: 200ms opacity ease-in-out;
  background-color: transparent;
  cursor: pointer;
}

.btn {
  opacity: 1;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .info-container,
  .slider {
    display: none;
  }
}

@media only screen and (max-width: 1250px) {
  .title.active,
  .title {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 1250px) {
  .desc.active,
  .desc {
    font-size: 1rem;
  }
}
